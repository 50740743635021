#projects {
  padding-top: 18px;
}

.coming-soon {
  filter: blur(16px);

  [aria-current="page"] {
    pointer-events: none;
    cursor: default;
    //text-decoration: none;
    //color: black;
  }

}