$font-size-base: 1.1rem;
$line-height-base: 1.6;

$darkLiver: rgba(0, 0, 00, 0);
$isabelline: rgba(241, 247, 237, 1);
$etonBlue: #843ca5;
$dingyDungeon: rgba(179, 57, 81, 1);
$flax: #67c9b8;

$primary: #5a4181;
$secondary: #843ca5;
//$success: ;
//$info: ;
//$warning: ;
//$danger: ;
$light: #67c9b8;
$dark: #482d72;

$primary-color: #5a4181;
$secondary-color: #843ca5;
$body-color: #fafafa;

$white: $flax;



//$grid-breakpoints: (
//        xs: 0,
//        sm: 700px,
//        md: 900px,
//        lg: 1200px,
//        xl: 1500px,
//        xxl: 2300px
//);
//$container-max-widths: (
//        sm: 600px,
//        md: 800px,
//        lg: 1100px,
//        xl: 1300px,
//        xxl: 2000px
//);

// Import Bootstrap and its default variables
@import "node_modules/bootstrap/scss/bootstrap";