@import "bootstrap";
@import "../components/projects/projects";

body {
  background: rgb(0, 0, 0);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(90, 65, 129, 1) 70%, rgba(132, 60, 165, 1) 100%);
}

//.xxx {
//  display: none;
//}
//@include media-breakpoint-up(sm) {
//  .xxx {
//    display: block;
//  }
//}


.header-logo {
  width: 6em;

  img {
    max-width: 6em;
  }
}

.header-social-links {
  margin-left: 2em;
}

.header-social-icon {
  border: 1px $primary-color solid;
  //width: 60px;
  //height: 60px;
  padding: 1em 0.3em;
  margin-left: 1em;

  img {
    width: 40px;
    margin: 1em;
  }
}

.header-menu {
  a.nav-link {
    color: $body-color !important;
  }
}

footer {
  color: $body-color;

  a {
    color: $body-color;
  }
}

.hero {
  img {
    margin: -120px auto;
  }
}

.project-card {
  background: rgb(103, 201, 184);
  background: radial-gradient(circle, $light 0%, $secondary 90%, $primary 100%);

  .project-card-drop {
    color: #fafafc;
  }
;
}

/**
    Video embed styles
 */

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}


/* End of Video embed styles */